import React from "react";

export function Logo() {
  return (
    <svg
      width="129"
      height="52"
      viewBox="0 0 129 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6619 14.1372H22.2035V26.6007H24.8434V14.1372H29.3857V11.9061H17.6619V14.1372Z"
        fill="#3E3E48"
      />
      <path
        d="M35.0999 15.4231C34.4981 15.4134 33.9088 15.5955 33.4173 15.9429C32.9262 16.289 32.5804 16.7803 32.38 17.417H32.2651V15.5803H29.7477V26.6007H32.3445V20.1234C32.3358 19.6884 32.4477 19.2595 32.6678 18.8843C32.8802 18.5274 33.1865 18.2357 33.5533 18.0411C33.9453 17.8343 34.3833 17.7304 34.8264 17.7388C35.0556 17.74 35.2845 17.7556 35.5117 17.7857C35.6881 17.8036 35.8624 17.8382 36.0323 17.8892V15.4941C35.884 15.4664 35.7341 15.4483 35.5835 15.4397C35.4037 15.4291 35.2427 15.4231 35.0999 15.4231Z"
        fill="#3E3E48"
      />
      <path
        d="M44.814 21.9654C44.814 22.525 44.6979 22.9904 44.4657 23.3616C44.2516 23.7153 43.9452 24.0039 43.5794 24.1965C43.2373 24.3796 42.8553 24.4757 42.4673 24.4761C41.8454 24.4761 41.3438 24.2728 40.9599 23.8595C40.5761 23.4463 40.3865 22.8947 40.3865 22.1973V15.5803H37.7897V22.597C37.7897 23.4775 37.9451 24.2272 38.2558 24.8463C38.5392 25.4347 38.9896 25.9266 39.5509 26.2607C40.1049 26.5825 40.7421 26.7435 41.4624 26.7435C42.2935 26.7435 42.9871 26.5493 43.5537 26.1556C44.1029 25.7851 44.5289 25.2589 44.777 24.6446H44.8926V26.6022H47.4108V15.5803H44.814V21.9654Z"
        fill="#3E3E48"
      />
      <path
        d="M55.4219 20.3198L53.5421 19.9171C52.982 19.7887 52.5813 19.624 52.34 19.423C52.2235 19.3294 52.1307 19.2097 52.069 19.0736C52.0073 18.9375 51.9785 18.7888 51.9849 18.6395C51.9803 18.2677 52.1609 17.9643 52.5266 17.729C52.8923 17.4938 53.3456 17.3765 53.8866 17.377C54.2361 17.3676 54.5835 17.4338 54.9051 17.5711C55.1633 17.6868 55.3908 17.8615 55.5692 18.0811C55.7284 18.2806 55.8466 18.5097 55.9167 18.7551L58.2839 18.4967C58.1071 17.5588 57.6467 16.8151 56.9027 16.2655C56.1588 15.716 55.1413 15.441 53.8503 15.4405C52.9709 15.4405 52.1947 15.578 51.5217 15.853C50.8508 16.1295 50.3272 16.5149 49.951 17.0158C49.5722 17.5265 49.3763 18.1498 49.3949 18.7853C49.3903 19.5872 49.6422 20.2503 50.1504 20.7746C50.6566 21.2982 51.4348 21.6722 52.4926 21.8906L54.3747 22.2842C54.8819 22.3935 55.2559 22.5514 55.4967 22.758C55.6127 22.8522 55.7057 22.9717 55.7685 23.1074C55.8314 23.243 55.8624 23.3912 55.8593 23.5407C55.8593 23.9139 55.6717 24.2267 55.2964 24.4791C54.9212 24.7314 54.425 24.8589 53.808 24.8614C53.2096 24.8614 52.7238 24.734 52.3506 24.4791C51.9773 24.2242 51.7335 23.8487 51.6192 23.3526L49.0866 23.5989C49.2443 24.6118 49.7346 25.4019 50.5577 25.969C51.3807 26.5362 52.4664 26.819 53.8148 26.8175C54.7331 26.8175 55.5463 26.6689 56.2545 26.3717C56.9627 26.0746 57.5167 25.6628 57.9167 25.1364C58.3151 24.6087 58.5276 23.9639 58.5211 23.3027C58.5166 22.5132 58.2589 21.8732 57.7497 21.3874C57.2405 20.9016 56.4645 20.5404 55.4219 20.3198Z"
        fill="#3E3E48"
      />
      <path
        d="M65.4215 24.5275C65.2733 24.5526 65.1231 24.5647 64.9727 24.5637C64.7724 24.5663 64.5732 24.5325 64.3849 24.464C64.2028 24.3915 64.0516 24.258 63.9573 24.0862C63.85 23.9029 63.7961 23.6364 63.7956 23.2869V17.5885H65.9701V15.5803H63.7926V12.9412H61.1958V15.5803H59.6318V17.5885H61.1958V23.7198C61.1922 24.4068 61.3416 24.9795 61.6438 25.4379C61.9437 25.8941 62.3753 26.2483 62.8814 26.4534C63.439 26.678 64.0381 26.7811 64.6388 26.7556C64.9536 26.7514 65.2673 26.7172 65.5757 26.6536C65.7756 26.6118 65.9731 26.5591 66.1672 26.4957L65.729 24.467C65.6588 24.4821 65.5545 24.5033 65.4215 24.5275Z"
        fill="#3E3E48"
      />
      <path
        d="M70.588 11.9061H67.9911V26.6007H70.588V11.9061Z"
        fill="#3E3E48"
      />
      <path
        d="M75.8866 15.5803H73.2898V26.6007H75.8866V15.5803Z"
        fill="#3E3E48"
      />
      <path
        d="M74.5954 11.1958C74.201 11.1894 73.8198 11.3375 73.5331 11.6083C73.3935 11.7339 73.2819 11.8873 73.2054 12.0587C73.1288 12.2301 73.0891 12.4156 73.0887 12.6033C73.0884 12.791 73.1274 12.9767 73.2033 13.1484C73.2792 13.32 73.3903 13.4739 73.5293 13.6C73.819 13.8662 74.1981 14.0139 74.5916 14.0139C74.9851 14.0139 75.3642 13.8662 75.6539 13.6C75.7932 13.4762 75.9045 13.3242 75.9805 13.1541C76.0564 12.9839 76.0953 12.7995 76.0944 12.6132C76.0957 12.4251 76.0572 12.2387 75.9812 12.0666C75.9053 11.8944 75.7937 11.7403 75.6539 11.6144C75.37 11.3409 74.9896 11.1905 74.5954 11.1958Z"
        fill="#3E3E48"
      />
      <path
        d="M86.4763 15.9248C85.9097 15.6004 85.2461 15.4377 84.4855 15.4367C83.6624 15.4367 82.9726 15.6188 82.416 15.983C81.8684 16.3336 81.4433 16.8458 81.1996 17.4487H81.0704V15.5803H78.5884V26.6007H81.1853V20.1445C81.1853 19.6131 81.2842 19.1598 81.4822 18.7845C81.6684 18.4246 81.9553 18.1267 82.308 17.927C82.6781 17.7239 83.0948 17.6213 83.5169 17.6293C84.1863 17.6293 84.7111 17.8373 85.0914 18.2534C85.4717 18.6694 85.6621 19.2462 85.6626 19.9836V26.5976H88.2587V19.5831C88.2637 18.7037 88.1096 17.9554 87.7963 17.3384C87.5071 16.7461 87.0474 16.2538 86.4763 15.9248Z"
        fill="#3E3E48"
      />
      <path
        d="M99.1582 16.7688C98.7022 16.3202 98.1541 15.9763 97.5519 15.7609C96.9336 15.5404 96.2816 15.4297 95.6252 15.4337C94.5725 15.4337 93.6565 15.6745 92.8773 16.156C92.0938 16.6401 91.4633 17.3361 91.0587 18.1635C90.6255 19.0228 90.4092 20.0191 90.4097 21.1524C90.4097 22.3054 90.6248 23.306 91.0549 24.1542C91.4619 24.978 92.105 25.6618 92.9022 26.1186C93.7036 26.5805 94.6569 26.8112 95.762 26.8107C96.6183 26.8107 97.3786 26.6803 98.043 26.4193C98.6686 26.1849 99.2303 25.8065 99.6825 25.3147C100.11 24.8406 100.41 24.2659 100.554 23.6442L98.1306 23.3707C98.0252 23.6686 97.8504 23.9372 97.6206 24.1542C97.3914 24.367 97.1201 24.5294 96.8243 24.631C96.494 24.7428 96.147 24.7973 95.7983 24.7919C95.2821 24.8041 94.7718 24.6793 94.3197 24.43C93.8961 24.1855 93.5543 23.8212 93.3374 23.3828C93.1108 22.9421 92.9942 22.4149 92.9876 21.8014H100.662V21.0036C100.662 20.038 100.529 19.2051 100.261 18.505C100.025 17.8524 99.6487 17.2597 99.1582 16.7688ZM92.9914 20.0471C93.013 19.6266 93.1273 19.2161 93.3261 18.845C93.5459 18.4347 93.8685 18.0884 94.2622 17.8401C94.6817 17.5803 95.1674 17.4475 95.6608 17.4578C96.1201 17.4479 96.5733 17.5645 96.9709 17.7948C97.3425 18.0156 97.6464 18.3342 97.8496 18.7158C98.0649 19.1263 98.1757 19.5835 98.1722 20.0471H92.9914Z"
        fill="#3E3E48"
      />
      <path
        d="M110.528 21.3859C110.018 20.8968 109.242 20.5402 108.2 20.316L106.32 19.9133C105.76 19.7849 105.36 19.6202 105.118 19.4192C105.001 19.3257 104.908 19.206 104.847 19.0699C104.785 18.9338 104.756 18.785 104.763 18.6357C104.758 18.264 104.939 17.9605 105.305 17.7253C105.67 17.49 106.124 17.3727 106.665 17.3732C107.014 17.3638 107.361 17.43 107.683 17.5673C107.941 17.683 108.168 17.8577 108.346 18.0773C108.506 18.2767 108.624 18.5058 108.694 18.7513L111.062 18.4929C110.885 17.555 110.425 16.8113 109.681 16.2618C108.937 15.7122 107.919 15.4372 106.628 15.4367C105.748 15.4367 104.972 15.5742 104.3 15.8492C103.628 16.1258 103.104 16.5111 102.729 17.012C102.35 17.5227 102.154 18.146 102.173 18.7815C102.168 19.5834 102.42 20.2465 102.928 20.7709C103.435 21.2945 104.217 21.6685 105.275 21.8868L107.155 22.2812C107.661 22.3905 108.035 22.5484 108.277 22.7549C108.393 22.8495 108.485 22.969 108.548 23.1046C108.611 23.2402 108.642 23.3883 108.64 23.5377C108.64 23.9109 108.452 24.2237 108.077 24.4761C107.701 24.7284 107.205 24.8559 106.587 24.8584C105.99 24.8584 105.505 24.7309 105.131 24.4761C104.757 24.2212 104.513 23.8457 104.399 23.3495L101.867 23.5959C102.025 24.6088 102.515 25.3988 103.338 25.966C104.16 26.5332 105.246 26.8165 106.594 26.816C107.513 26.816 108.326 26.6674 109.034 26.3702C109.742 26.073 110.296 25.6613 110.696 25.1349C111.094 24.607 111.307 23.9624 111.301 23.3012C111.294 22.5109 111.037 21.8717 110.528 21.3859Z"
        fill="#3E3E48"
      />
      <path
        d="M42.536 34.345L39.3552 34.3578L39.3756 39.4872L40.15 39.4842L40.141 37.2478L42.32 37.2387L42.3169 36.5754L40.1387 36.5837L40.1319 35.0212L42.5391 35.0114L42.536 34.345Z"
        fill="#3E3E48"
      />
      <path
        d="M46.0712 34.2611C44.7392 34.2664 43.7638 35.2645 43.7698 36.9055C43.7759 38.5466 44.7596 39.5364 46.0924 39.5311C47.4252 39.5258 48.3976 38.5277 48.3908 36.8867C48.384 35.2456 47.3987 34.2558 46.0712 34.2611ZM46.0894 38.8246C45.2054 38.8246 44.5443 38.1514 44.539 36.9018C44.5337 35.6521 45.1903 34.9706 46.0743 34.9676C46.9582 34.9645 47.6193 35.64 47.6246 36.8897C47.6299 38.1394 46.9711 38.8209 46.0894 38.8246Z"
        fill="#3E3E48"
      />
      <path
        d="M53.1922 34.3027L53.2051 37.6143C53.2051 38.3101 52.7291 38.8133 51.9471 38.8163C51.1651 38.8194 50.6831 38.32 50.6808 37.6218L50.6672 34.311H49.8935L49.9071 37.6845C49.9109 38.7612 50.7208 39.522 51.9471 39.5167C53.1734 39.5114 53.9825 38.7453 53.9788 37.6679L53.9682 34.2974L53.1922 34.3027Z"
        fill="#3E3E48"
      />
      <path
        d="M59.0863 34.2793L59.1014 38.0464H59.0538L56.429 34.2898L55.7128 34.2929L55.7332 39.4223L56.5068 39.4192L56.4917 35.6574H56.5393L59.1618 39.4087L59.8728 39.4056L59.8524 34.2762L59.0863 34.2793Z"
        fill="#3E3E48"
      />
      <path
        d="M63.3264 34.2627L61.6136 34.2695L61.634 39.3989L63.2962 39.3921C64.8587 39.386 65.7593 38.4099 65.7525 36.8104C65.7457 35.2109 64.8368 34.2566 63.3264 34.2627ZM63.2509 38.7166H62.4069L62.3918 34.9389L63.2833 34.9351C64.4053 34.9313 64.9863 35.6 64.9916 36.8096C64.9969 38.0192 64.4189 38.7113 63.2493 38.7166H63.2509Z"
        fill="#3E3E48"
      />
      <path
        d="M68.3364 34.243L66.511 39.3807H67.3323L67.7985 38.0207L69.8014 38.0124L70.2751 39.3686L71.0972 39.3649L69.231 34.243H68.3364ZM68.0206 37.3649L68.7686 35.1738H68.8086L69.577 37.3589L68.0206 37.3649Z"
        fill="#3E3E48"
      />
      <path
        d="M75.4 34.2151L71.4296 34.2309L71.4326 34.8966L73.0306 34.8905L73.048 39.3535L73.8194 39.3505L73.802 34.8875L75.4023 34.8814L75.4 34.2151Z"
        fill="#3E3E48"
      />
      <path
        d="M77.56 34.203L76.7863 34.2061L76.8069 39.3355L77.5806 39.3324L77.56 34.203Z"
        fill="#3E3E48"
      />
      <path
        d="M81.4459 34.1206C80.1131 34.1259 79.1377 35.124 79.1445 36.765C79.1513 38.4061 80.1343 39.3958 81.4663 39.3906C82.7983 39.3853 83.7715 38.3872 83.7654 36.7461C83.7594 35.1051 82.7734 34.1153 81.4459 34.1206ZM81.464 38.6841C80.5793 38.6879 79.9182 38.0117 79.9137 36.762C79.9091 35.5123 80.5642 34.8308 81.4482 34.8271C82.3322 34.8233 82.994 35.4995 82.9986 36.7492C83.0031 37.9988 82.3458 38.6796 81.464 38.6841Z"
        fill="#3E3E48"
      />
      <path
        d="M89.4388 34.1591L88.6719 34.1622L88.6871 37.9293H88.6395L86.0147 34.1727L85.2984 34.1758L85.3188 39.3052L86.0933 39.3021L86.0782 35.5403L86.1258 35.5395L88.7475 39.2916L89.4592 39.2885L89.4388 34.1591Z"
        fill="#3E3E48"
      />
    </svg>
  );
}
